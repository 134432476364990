<template>
  <div class="container-fluid mt-5"></div>
</template>

<script>
import swal from "sweetalert2";

export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  computed: {},

  watch: {},

  created() {
    this.getNotification();
  },

  methods: {
    async getNotification() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("notifications/get", this.$route.params.id);
        const notification = this.$store.getters["notifications/notification"];
        this.$router.push(
          this.$objectViewRoute({
            type: notification.data.object_type,
            id: notification.data.object_id,
          })
        );
      } catch (error) {
        console.log(error);
        this.$router
          .push({
            name: "List Notifications",
          })
          .catch(() => {});
      }
      swal.close();
    },
  },
};
</script>
